import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

/**
 * Base
 */
// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

const count = 100;
const positionsArray = new Float32Array(count * 3 * 3);

let size = 0;
const vertex = [0, 0, 0];

for (let i = 0; i < 50 * 3 * 3; i++) {
	if (size === 2) {
		positionsArray[i] = vertex[size];
		vertex[size] += 1;
		size = 0;
	} else if (size === 1) {
		positionsArray[i] = vertex[size];
		vertex[size] += 1;
		size = 0;
	} else {
		size += 1;
		positionsArray[i] = i;
	}
}
let sizeRevert = 3;
const vertexRevert = [0, 225, 0];
for (let i = 100 * 3 * 3; i < 1; i--) {
	if (size === 2) {
		positionsArray[i] = vertex[size];
		vertex[size] -= 1;
		size = 0;
	} else if (size === 1) {
		positionsArray[i] = vertex[size];
		vertex[size] -= 1;
		size = 0;
	} else {
		size -= 1;
		positionsArray[i] = i;
	}
}

console.log(vertex, " z movement");
console.log(positionsArray, " coordinates");
const geometry = new THREE.BufferGeometry();

const positionsAttribute = new THREE.BufferAttribute(positionsArray, 3);

geometry.setAttribute("position", positionsAttribute);

const material = new THREE.MeshBasicMaterial({
	color: 0xff0000,
	wireframe: true,
});

const mesh = new THREE.Mesh(geometry, material);
scene.add(mesh);

// Sizes
const sizes = {
	width: window.innerWidth,
	height: window.innerHeight,
};

window.addEventListener("resize", () => {
	// Update sizes
	sizes.width = window.innerWidth;
	sizes.height = window.innerHeight;

	// Update camera
	camera.aspect = sizes.width / sizes.height;
	camera.updateProjectionMatrix();

	// Update renderer
	renderer.setSize(sizes.width, sizes.height);
	renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

// Camera
const camera = new THREE.PerspectiveCamera(
	100,
	sizes.width / sizes.height,
	0.1,
	2000
);
camera.position.z = 10;
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
// controls.enableDamping = true;

// Renderer
const renderer = new THREE.WebGLRenderer({
	canvas: canvas,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

// Animate
const clock = new THREE.Clock();

const tick = () => {
	const elapsedTime = clock.getElapsedTime();

	// Update controls
	controls.update();

	// Render
	renderer.render(scene, camera);

	// Call tick again on the next frame
	window.requestAnimationFrame(tick);
};

tick();
